<template>
  <div class="container">
    <goback :msg='msg' url="/"></goback>
    <!-- 审核 -->
    <div class="examine" v-if="examineShow">
      <div class="examine-top">
        <img src="../../assets/images/tjcg.png">
        <p>已提交成功，请您稍后回来查看预核保结果</p>
      </div>
      <div class="examine-bot">
        <div class="title">
          <img src="../../assets/images/blzy.png">疾病知识
        </div>
        <div class="box">
          <div><span class="font-red">Q</span> {{q}}</div>
          <div><span class="font-bule">A</span> {{a}}
          </div>
          <div class="refresh" @click="getQuestion"><img src="../../assets/images/hyh.png">换一换</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="result-top" v-if="result =='02' && !this.$route.query.supplement">
        <div class="result-img">
          <img src="../../assets/images/jj.png" style="width:28px; height:28px">
        </div>
        <div class="tips">
          <p>很抱歉，您当前的情况不符合投保规则，不接受投保。</p>
          <p v-if="resultMessage!=''">原因：{{resultMessage}}</p>
<!--          <div class="font-red margin-top-15">注：本结果的出具仅基于您当前已提供的部分信息，复星联合健康拥有正式核保决定及最终解释权。</div>-->
          <div class="font-red margin-top-15">注：本结果的出具仅基于您当前已提供的部分信息，保险公司拥有正式核保决定及最终解释权。</div>
        </div>
      </div>
      <div class="result-top" v-if="result=='01'">
        <div class="result-img">
          <img src="../../assets/images/cg.png" style="width:28px; height:28px">
        </div>
        <div class="tips">
          <p>恭喜您，根据您提供的信息，初步判定您基本符合投保条件，请点击页面底部链接进行投保</p>
          <p v-if="!isOptionalLiability && !$route.query.status">重大疾病保险金（可选）：不接受，仅能投保基本责任</p>
<!--          <div class="font-red margin-top-15">注：本结果的出具仅基于您当前已提供的部分信息，复星联合健康拥有正式核保决定及最终解释权。</div>-->
          <div class="font-red margin-top-15">注：本结果的出具仅基于您当前已提供的部分信息，xx保险公司拥有正式核保决定及最终解释权。</div>
        </div>
      </div>
      <div class="result-top" v-if="result=='03' || result=='05' || this.$route.query.supplement">
        <div class="result-img">
          <img src="../../assets/images/xxbz.png" style="width:26px; height:28px">
        </div>
        <div class="tips">
          <p>当前信息不足，请您补充对应资料后再行评估。</p>
          <div>
            需补充资料类型:
            <span v-for="(item,index) in lackInformationList" style="font-weight: bold">
              {{item}}<i v-if="(lackInformationList.length-1) != index">、</i>
            </span>
            <span v-if="lackInformationList.length!=0">，{{resultMessage}}</span>
            <span v-else>{{resultMessage}}</span>
          </div>
        </div>
      </div>
      <div class="result-top" v-if="result =='04'">
        <div class="result-img">
          <img src="../../assets/images/jj.png" style="width:28px; height:28px">
        </div>
        <div class="tips">
          <p>很抱歉，您当前的情况不符合投保规则，不接受投保。</p>
          <p v-if="resultMessage!=''">原因：{{resultMessage}}</p>
<!--          <div class="font-red margin-top-15">注：本结果的出具仅基于您当前已提供的部分信息，复星联合健康拥有正式核保决定及最终解释权。</div>-->
          <div class="font-red margin-top-15">注：本结果的出具仅基于您当前已提供的部分信息，xx保险公司拥有正式核保决定及最终解释权。</div>
        </div>
      </div>
      <div class="buchong" v-if="result=='03' || result=='05' || this.$route.query.supplement">
        <div class="buchong-title">补充上传</div>
        <div class="buchong-box">
          <upload @getFiles="getFiles" :picList="picList" :pdfList="pdfList"  @getFilespdf="getFilespdf"></upload>
          <div>请您尽量提供齐全的资料，包括但不限于：
            <span v-for="(item,index) in lackInformationList" >{{item}}<i v-if="(lackInformationList.length-1) != index">、</i></span>
          </div>
           <div class="bc-btn" @click="getSignature">提交</div>
        </div>
      </div>
      <div class="result-bot">
        <div class="title" v-if="plan">
          <img src="../../assets/images/bxjh.png">保险计划
        </div>
        <div class="tab-box" v-if="plan">
          <div class="title">根据您选择的保额及赔付比例，已匹配为{{plan.name}}：</div>
          <table width="100%" cellspacing="0"border="1px">
            <tr>
              <th>保障类型</th>
              <th>限额</th>
              <th>赔付比例</th>
            </tr>
            <tr>
              <td style="font-weight: bold;">乳腺癌首次复发医疗保险金限额</td>
              <td colspan="2" style="text-align: center;font-weight: bold;">{{plan.other.passResultExcel.startingLimit}}</td>
            </tr>
            <tr>
              <td>社保范围内的自付和个人首先自付费用</td>
              <td style="text-align: center; width:20%">{{plan.other.passResultExcel.inSocialSelfCostLimit}}</td>
              <td style="text-align: center; width:20%">{{plan.other.passResultExcel.inSocialSelfPercentage}}</td>
            </tr>
            <tr>
              <td>社保范围外的自费费用</td>
              <td style="text-align: center; width:20%">{{plan.other.passResultExcel.outSocialSelfCostLimit}}</td>
              <td style="text-align: center; width:20%">{{plan.other.passResultExcel.outSocialSelfPercentage}}</td>
            </tr>
            <tr>
              <td>特定恶性肿瘤药品费用</td>
              <td style="text-align: center; width:20%">{{plan.other.passResultExcel.tumorDrugCostLimit}}</td>
              <td style="text-align: center; width:20%">{{plan.other.passResultExcel.tumorDrugPercentage}}</td>
            </tr>
            <tr v-if="caseDetail.isSeriousIllAmount1W">
              <td style="font-weight: bold;">重大疾病保险金金额</td>
              <td colspan="2" style="text-align: center;font-weight: bold;">定额1万元</td>
            </tr>
          </table>
          <table width="100%" cellspacing="0"border="1px" style="text-align: center;"  v-if="yearCharge.firstYear" >
            <tr>
              <th>单位：元/年</th>
              <th>含可选</th>
              <th>不含可选</th>
              <th>对应年龄段</th>
            </tr>
            <tr v-if="yearCharge.firstYear">
              <td>首期年缴保费</td>
              <td>{{yearCharge.firstYear.withOptional}}</td>
              <td>{{yearCharge.firstYear.withoutOptional}}</td>
              <td>{{yearCharge.firstYear.ageGroup}}</td>
            </tr>
            <tr  v-for="(item,index) in yearCharge.followingYear">
              <td :rowspan="yearCharge.followingYear.length" v-if="index=='0'">后续年缴保费</td>
              <td style="padding-left: 0">{{item.withOptional}}</td>
              <td>{{item.withoutOptional}}</td>
              <td>{{item.ageGroup}}</td>
            </tr>
          </table>
          <div style="padding:.15rem .2rem;" v-if="isIhcWarning">
            <van-icon name="warning-o" color="#ff0000" /> 免疫组化与分型不一致，费率无法明确，请核实免疫组化及分型是否填写正确！建议上传病历由专业核保师协助处理。
          </div>
          <div class="font-bule check" @click="layer = true">若想调整保险计划或基本信息，可点击此处修改</div>
              <van-overlay :show="layer" @click="layer = false">
              <div class="wrapper" @click.stop>
                  <div class="layer-box">
                    <div class="layer-title">信息修改</div>
                    <basics :caseDetail='caseDetail' :getname='name' :getbirthday='birthday' :initList='initList'
                    @getBasics='getBasics' @getNewName='getNewName' @getNewBirthday='getNewBirthday'> </basics>
                    <div class="layer-btn-box">
                      <div class="layer-btn"  @click="layer = false"> 取消</div>
                      <div class="layer-btn bg-blue" @click="onSubmit">提交</div>
                    </div>
                  </div>
              </div>
            </van-overlay>
        </div>
      </div>
      <div class="result-bot" v-if="totalImageCount && totalImageCount!=''">
        <div class="title">
          <img src="../../assets/images/blzy.png">病历摘要
        </div>
        <div class="box">
          <div class="summary" v-if="totalImageCount">
            <p>提供资料总计<span class="font-red">{{totalImageCount}}</span>页（重复图像未计）:</p>
            <p>
              <span v-for="(item,index) in imageInfoMap">{{getImgName(item.informationType)}}<span class="font-red">{{item.informationCount}}</span>页<i v-if="imageInfoMap.length-1!==index">、</i></span>
            </p>
            <div class="font-bule check" @click="jump">查看我已上传的图像</div>
          </div>
          <div class="list" >
            <div class="list-title">
              基本信息摘要
            </div>
            <div class="list-box">
              <div class="basic" v-if="basicSummary.name && basicSummary.name.length!=0">
                <div v-if="basicSummary.name && basicSummary.name.length!=0">姓名:<span v-for="item in basicSummary.name">{{item}}</span></div>
                <div style="text-align: right"  v-if="basicSummary.gender &&basicSummary.gender.length!=0">性别：<span v-for="item in basicSummary.gender">{{item}}</span></div>
              </div>
              <div v-else>
                <div v-if="basicSummary.name && basicSummary.name.length!=0">姓名:<span v-for="item in basicSummary.name">{{item}}</span></div>
                <div v-if="basicSummary.gender &&basicSummary.gender.length!=0">性别：<span v-for="item in basicSummary.gender">{{item}}</span></div>
              </div>
              <div class="basic">
                <div v-if="basicSummary.age && basicSummary.age.length!=0">年龄：<span style="margin-right: .15rem" v-for="item in basicSummary.age">{{item}}</span></div>
                <div v-if="basicSummary.birthday && basicSummary.birthday.length!=0">出生年月：<span v-for="item in basicSummary.birthday">{{item}}</span></div>
              </div>
            </div>
            <div class="list-title" v-if="hospitalSummary">
              就诊摘要
            </div>
            <div class="list-box">
              <div class="details" v-for="item in hospitalSummary">
                <div v-if="item.time && item.time.length !=0">
                  就诊时间：<span class="span-right" v-for="ite in item.time">{{ite}}</span>
                </div>
                <div style="display: flex;justify-content: space-between;flex-wrap: wrap;" v-if="item.diagnose && item.diagnose.length !=0" >
                  <div>
                    诊断：
                  </div>
                  <div style="width: 70%; text-align: right">
                    <span v-for="(ite,index) in item.diagnose">{{ite}}<i v-if="item.diagnose.length-1 != index">、</i></span>
                  </div>
                </div>
                <div style="display: flex;justify-content: space-between;flex-wrap: wrap;" v-if="item.clinicComp && item.clinicComp.length !=0" >
                  <div>是否新辅助治疗：</div>
                  <div style="width: 55%;  text-align: right">
                    <span v-for="(ite,index) in item.clinicComp">{{ite}}<i v-if="item.clinicComp.length-1 != index">、</i></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-title">
              其他关键摘要
            </div>
            <div class="list-box">
              <div class="details"  style="display: flex;justify-content: space-between;flex-wrap: wrap;"v-if="otherSummary.staging && otherSummary.staging.length !=0">
                <div>分期：</div>
                <div style="width: 70%; text-align: right"><span v-for="(item,index) in otherSummary.staging">{{item}}<i v-if="otherSummary.staging.length-1 != index">、</i></span></div>
              </div>
              <div class="details" style="display: flex;justify-content: space-between;flex-wrap: wrap;" v-if="otherSummary.typing && otherSummary.typing.length !=0">
                <div>分型：</div>
                <div style="width: 70%; text-align: right"><span v-for="(item,index) in otherSummary.typing ">{{item}}<i v-if="otherSummary.typing.length-1 != index">、</i></span></div>
              </div>
              <div class="details"  style="display: flex;justify-content: space-between;flex-wrap: wrap;" v-if="otherSummary.operationHospital && otherSummary.operationHospital.length !=0">
                <div>手术医院：</div>
                <div style="width: 70%; text-align: right"><span v-for="(item,index) in otherSummary.operationHospital">{{item}}<i v-if="otherSummary.operationHospital.length-1 != index">、</i></span></div>
              </div>
              <div v-if="operations.length !=0">
                <div class="details" v-for="item in operations">
                  <div v-if="item.time && item.time.length !=0">手术时间：<span v-for="ite in item.time">{{ite}}</span></div>
                  <div v-if="item.operationName && item.operationName.length !=0" style="display: flex;justify-content: space-between;
flex-wrap: wrap;">
                    <div>手术名称：</div>
                    <div style="width: 70%;text-align: right"><span v-for="(ite,index) in item.operationName">{{ite}}<i v-if="item.operationName.length-1 != index">、</i></span></div>
                  </div>
                </div>
              </div>
              <div class="details"  style="display: flex;justify-content: space-between;flex-wrap: wrap;"  v-if="otherSummary.tumorSize && otherSummary.tumorSize.length !=0">
                <div> 肿物大小：</div>
                <div  style="width: 70%; text-align: right"><span  v-for="(item,index) in otherSummary.tumorSize">{{item}}<i v-if="otherSummary.tumorSize.length-1 != index">、</i></span></div>
              </div>
              <div class="details" style="display: flex;justify-content: space-between;flex-wrap: wrap;" v-if="otherSummary.cuttingEdge && otherSummary.cuttingEdge.length !=0">
                <div > 切缘：</div>
                <div style="width: 70%;  text-align: right">
                  <i v-for="item in otherSummary.cuttingEdge">{{item}}</i>
                </div>
              </div>
              <div class="details" v-if="otherSummary.regionalLymphNode && otherSummary.regionalLymphNode.length !=0" style="display: flex;justify-content: space-between;flex-wrap: wrap;">
                <div>区域淋巴结：</div>
                <div style="width: 70%;  text-align: right">
                  <span v-for="(item,index) in otherSummary.regionalLymphNode">{{item}}</span>
                </div>
              </div>
              <div class="details" style="display: flex;justify-content: space-between;flex-wrap: wrap;" v-if="otherSummary.pathologicDiagnosis && otherSummary.pathologicDiagnosis.length !=0">
                <div>病理诊断：</div>
                <div style="width: 70%;  text-align: right">
                  <span v-for="(item,index) in otherSummary.pathologicDiagnosis">{{item}}<i v-if="otherSummary.pathologicDiagnosis.length-1 != index">、</i></span>
                </div>
              </div>
<!--              <div class="details" style="display: flex;justify-content: space-between;flex-wrap: wrap;" v-if="otherSummary.isMetastasis && otherSummary.isMetastasis.length !=0">-->
<!--                <div>术后至今是否确诊为/怀疑为复发或远处转移：</div>-->
<!--                <div style="text-align: right"><span v-for="(item,index) in otherSummary.isMetastasis">{{getYorN(item)}}<i v-if="otherSummary.isMetastasis.length-1 != index">、</i></span></span></div>-->
<!--              </div>-->
            </div>
          </div>
        </div>

      </div>
      <div class="result-bot" style="margin-top: .4rem">
        <div class="title">
          <img src="../../assets/images/blzy.png">已填信息
          <span v-if="this.$route.query.status == '04'" class="modify"  @click="jumpUpload">填写有误，返回修改</span>
        </div>
        <div class="box">
          <div class="list">
            <div class="list-box">
              <div class="basic">
                <div>姓名:<span>{{name}}</span></div>
                <div style="text-align: right">出生年月：<span>{{birthday}}</span></div>
              </div>
              <div class="details" >
                有无社保：<span class="span-right">{{getYorY(caseDetail.isSocialSecurity)}}</span>
              </div>
              <div class="details">
                是否新辅助治疗：<span class="span-right">{{getYorN(caseDetail.isCompTherapy)}}</span>
              </div>
              <div class="details">
                保额：<span class="span-right">{{caseDetail.insuredSum}}万</span>
              </div>
              <div class="details">
                社保范围内的自付费用赔付比例：<span class="span-right">{{caseDetail.inSocialPercentage}}%</span>
              </div>
              <div class="details">
                社保范围外的自费费用赔付比例：<span class="span-right">{{caseDetail.outSocialPercentage}}%</span>
              </div>
              <div class="details">
                重大疾病保险金1万(可选)：<span class="span-right" v-if="caseDetail.isSeriousIllAmount1W">投保</span><span class="span-right" v-else>不投保</span>
              </div>
            </div>
            <div class="list-box">
              <div class="details" style="display: flex" v-if="quezhen && quezhen.length !=0">
                <div>已确诊:</div>
                <div style="width: 80%; margin-left: 20px; text-align: right">
                  <i  v-for="(item,index) in quezhen">{{item}}<i v-if="(quezhen.length-1) !== index">、</i></i></div>
                </div>
              <div class="details" style="display: flex" v-if="shoushu && shoushu.length !=0">
                <div>已手术: </div>
                <div style="width: 80%; margin-left: 20px; text-align: right">
                  <i v-for="(item,index) in shoushu">{{item}}<i v-if="shoushu.length-1 !== index">、</i></i>
                </div>
              </div>
              <div class="details" v-if="caseDetail.cutEdgeResults"> <div>切缘: <span style="float: right">{{getFilter(this.initList.cutEdgeResults,this.caseDetail.cutEdgeResults)}}</span></div></div>
              <div class="details" v-if="caseDetail.pathologyTyping"> <div>分期:<span  class="span-right">{{getFilter(this.initList.pathologyTyping,this.caseDetail.pathologyTyping)}}</span></div></div>
              <div class="details" v-if="caseDetail.ihcEr || caseDetail.ihcPr || caseDetail.ihcKi67 || caseDetail.ihcHer2 || caseDetail.ihcFish"> <div>免疫组化</div></div>
              <div class="details" v-if="caseDetail.ihcEr">ER:<span  class="span-right">{{getFilter(this.initList.ihcErType,this.caseDetail.ihcEr)}}</span></div>
              <div class="details" v-if="caseDetail.ihcPr">PR:<span  class="span-right">{{getFilter(this.initList.ihcPrType,this.caseDetail.ihcPr)}}</span></div>
              <div class="details" v-if="caseDetail.ihcKi67">Ki-67:<span  class="span-right">{{getFilter(this.initList.ihcKi67Type,this.caseDetail.ihcKi67)}}</span></div>
              <div class="details" v-if="caseDetail.ihcHer2">Her-2:<span  class="span-right">{{getFilter(this.initList.ihcHer2Type,this.caseDetail.ihcHer2)}}</span></div>
              <div class="details" v-if="caseDetail.ihcHer2== '20' && caseDetail.ihcFish ">FISH检测是否扩散: <span  class="span-right" v-if="caseDetail.ihcFish">是</span> <span  class="span-right" v-else>否</span></div>
              <div class="details" v-if="caseDetail.ihcTyping">分型: <span  class="span-right">{{getFilter(this.initList.ihcTyping,this.caseDetail.ihcTyping)}}</span> </div>
              <div class="details">
                术后至今是否确诊为/怀疑为复发或远处转移：<span class="span-right" v-if="caseDetail.isMetastasis">是</span><span class="span-right" v-else>否</span>
              </div>
            </div>

          </div>
        </div>

      </div>
      <div class="jubao" v-if="!totalImageCount">
        <div class="font-red">上述信息均为您填写内容，想要获取更详细全面的投保，建议上传病历</div>
        <div class="jubao-btn" @click="jumpUpload">点击上传</div>
      </div>
      <div class="buchong" v-if="result=='04' || (result=='01' && !$route.query.status)">
        <div class="buchong-title">补充上传</div>
        <div class="buchong-box">
          <upload @getFiles="getFiles" :picList="picList" :pdfList="pdfList" @getFilespdf="getFilespdf"></upload>
          <div>若您有新的资料可申请重新审核，添加新资料后提交</div>
           <div class="bc-btn" @click="getSignature">提交</div>
        </div>
      </div>
      <div  v-if="result=='01'  && !$route.query.status" style="width:100%; height:56px"></div>
      <div v-if="result=='01'  && !$route.query.status" class="btn" @click="getInsure" >立即投保</div>
    </div>
    <van-popup v-model="chongfuShow" round :close-on-click-overlay='false' style="width: 5rem; padding: 20px">
      <div>
        <div style="line-height: .4rem"> 本次上传存在{{this.chongfu}}张重复资料，我们将剔除后上传，请知悉</div>
        <div style="text-align: center; margin-top: .2rem;line-height: 32px"><van-button type="info" size="small" style="line-height: 32px" @click="implement('5')">已知悉 0{{time}} S</van-button></div>
      </div>
    </van-popup>
    <van-popup v-model="chongfuAll" round :close-on-click-overlay='false' style="width: 5rem; padding: 20px">
      <div>
        <div style="line-height: .4rem"> 本次上传图片此前均已提交，仅需补充新资料，请重新添加</div>
        <div style="text-align: center; margin-top: .2rem;line-height: 32px"><van-button type="info" size="small" style="line-height: 32px" @click="implement('10')">已知悉 {{Alltime}} S</van-button></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import $ from '../../util/jquery.js'
import {question, listDetail, enumList, passEdit, caseSubmit, getSigned, rgaInsureUrl, pdfUploadOver,delOssPath} from "@/api";
import Vue from "vue";
import goback from "../goBack/index.vue";
import basics from "./basics.vue"
import {
  Overlay,
  Toast
} from "vant";
import upload from "../upload/upload.vue";
Vue.use(Overlay);
export default {
  name: "caseList",
  data() {
    return {
      msg: "我的预核保",
      examineShow: false,
      a: "",
      q: "",
      summaryJson: [],
      result: "",
      resultMessage: "",
      basicSummary: {},
      otherSummary: {},
      totalImageCount: "",
      initList: [],
      imageInfoMap: [],
      yearCharge: {},
      layer:false,
      plan:null,
      caseDetail:{},
      birthday:"",
      name:"",
      hospitalSummary:[],
      newRuleForm:{},
      operations:[],
      dataObj:{},
      uploadQueue:[],
      lackInformationList:[],
      quezhen:[],
      shoushu:[],
      clinicComp:[],
      isOptionalLiability:true,
      isIhcWarning:false,
      chongfuShow:false,
      chongfu:0,
      time:0,
      timer:null,
      shibai:0,
      timename:null,
      uploadQueuePdf:[],
      fileAll:[],
      total:"",
      chongfuAll:false,
      Alltime:0,
      allTimer:null,
      picList:[],
      pdfList:[],
      loading:null
    }
  },
  components: {
    goback,
    upload,
    basics
  },
  filters: {},
  created() {
    this.getInit();
  },
  methods: {
    getInit() {
      enumList().then((res) => {
        let obj = {};
        res.map((e) => {
          obj[e.enumType] = e.enumList;
        });
        this.initList = obj;
        if (this.$route.query.type == "01") {
          this.examineShow = true;
          this.getQuestion();
        } else {
          this.getListDetail();
        }
      });
    },
    getImgName(val) {
      return this.initList.informationType.filter(
        (item) => item.code === val
      )[0].name;
    },
    getQuestion() {
      question().then((res) => {
        this.a = res.a;
        this.q = res.q;
      });
    },
    getListDetail() {
      listDetail(this.$route.query.caseId).then((res) => {
        if(res.summaryJson){
          this.basicSummary = res.summaryJson.basicSummary,
          this.otherSummary = res.summaryJson.otherSummary;
          this.clinicComp = res.summaryJson.clinicComp;
          this.hospitalSummary = res.summaryJson.hospitalSummary;
          if(res.summaryJson.operationMajorInfo){
               this.operations = res.summaryJson.operationMajorInfo.operations
          }
        }
        this.result = res.result,
        this.resultMessage = res.resultMessage;
        this.totalImageCount = res.totalImageCount;
        this.imageInfoMap = res.informationTypeCountList;
        this.caseDetail = res.caseDetail;
        this.isOptionalLiability = res.isOptionalLiability;
        this.isIhcWarning = res.isIhcWarning;
        this.name = res.name;
        this.birthday = res.birthday
        if(res.yearCharge){
          this.yearCharge = res.yearCharge;
          this.plan = this.initList.securityPlan.filter(
           (item) => item.code === res.yearCharge.plan)[0];
        }
        if(res.lackInformationList){
          this.lackInformationList = res.lackInformationList
        }
        if(res.caseDetail.definiteSicknessTypeList){
          let box =[]
          let date = this.initList.definiteSicknessType.filter(item => res.caseDetail.definiteSicknessTypeList.indexOf(item.code) > -1)
          for(let i=0; i < date.length; i++){
            box.push(date[i].name)
          }
          if(box.length!=0){
            this.quezhen = [...new Set(box)]
          }

        }
        if(res.caseDetail.completedOperationTypeList){
          let box =[]
          let date = this.initList.completedOperationType.filter(item => res.caseDetail.completedOperationTypeList.indexOf(item.code) > -1)
          for(let i=0; i < date.length; i++){
            box.push(date[i].name)
          }
          if(box.length!=0){
            this.shoushu = [...new Set(box)]
          }
        }
      });
    },
    getYorY(val) {
      if (val == true) {
        return "有";
      } else {
        return "无";
      }
    },
    getYorN(val) {
      if (val == true) {
        return "是";
      } else {
        return "否";
      }
    },
    jump() {
      this.$router.push({
        path: "/caseImgs",
        query: { caseId: this.$route.query.caseId },
      });
    },
    jumpUpload() {
      this.$router.push({
        path: "/caseUpload",
        query: { caseId: this.$route.query.caseId },
      });
    },
    //获取信息
    getBasics(val){
      this.newRuleForm = val
    },
    getNewBirthday(val){
      this.newRuleForm.birthday = val
    },
    getNewName(val){
       this.newRuleForm.name = val
    },
    //提交
    onSubmit(){
       const toast = Toast.loading({
         duration: 0, // 持续展示 toast
         forbidClick: true,
         message: "正在提交请稍后..."
      });
      this.newRuleForm.caseId =this.$route.query.caseId
      passEdit(this.newRuleForm).then((res) => {
        toast.clear()
        Toast('提交成功');
        this.layer = false
       // this.$router.push({path:'/'});
        this.$router.push({path:'/caseList'});
       //this.getListDetail();
         // caseSubmit(this.$route.query.caseId).then((res) => {
         //    toast.clear()
         //     Toast('提交成功');
         //    this.layer = false
         //     this.getListDetail();
         //    //this.$router.push({path:'/caseList'});
         //  })
      });
    },
    getFiles(files,type){
      if(type){
        this.uploadQueue = []
      }
      for( let i = files.length - 1; i >= 0; --i )
        {
         this.uploadQueue.push( files[i] );
        }
      console.log(this.uploadQueue)
    },
    getFilespdf(files,type){
      if(type){
        this.uploadQueuePdf = []
      }
      for( let i = files.length - 1; i >= 0; --i ){
        this.uploadQueuePdf.push( files[i] );
      }
    },
    //获取oss签名
    getSignature(){
      if(this.uploadQueue.length == 0 && this.uploadQueuePdf.length == 0){
         Toast('请上传病历资料');
         return
      }
      this.fileAll = [...this.uploadQueue,...this.uploadQueuePdf]
      this.total = this.fileAll.length
      getSigned(this.$route.query.caseId).then((res) => {
        this.dataObj=res
        this.startUploadQueue()
       })
      this.timename=setInterval(
          function() {
            this.timegetSignature()
          }.bind(this),
          28000
      )
    },
    timegetSignature(){
      getSigned(this.$route.query.caseId).then((res) => {
        this.dataObj=res
      })
    },
    //查看pdf是否提交成功
    getpdfUploadOver(){
      this.pdfOver = setInterval(this.getpfdover ,
          500
      )
    },
    getpfdover(){
      pdfUploadOver(this.$route.query.caseId).then((res) => {
        if(res){
          clearInterval(this.pdfOver)
          setTimeout(()=>{
            caseSubmit(this.$route.query.caseId).then((res) => {
              this.loading.clear()
              Toast('提交成功');
              //this.$router.push({path:'/'});
              this.$router.push({path:'/caseList'});
            })
            return;
          }, 5000);
        }
      })
      .catch((err) => {
        clearInterval(this.pdfOver)
      })
    },
    startUploadQueue(){
      const toast = Toast.loading({
         duration: 0, // 持续展示 toast
         forbidClick: true,
         message: "正在提交请稍后..."
      });
      if( this.fileAll.length <= 0 ){
        toast.clear()
        clearInterval(this.timename)
        if(this.chongfu != 0){
          if(this.chongfu == this.total){
            this.chongfuAll = true
            const TIME_COUNT = 10;
            this.Alltime = TIME_COUNT;
            this.allTimer = setInterval(() => {
              if (this.Alltime > 0 && this.Alltime <= TIME_COUNT) {
                this.Alltime--;
              } else {
                this.chongfuAll = false;
                this.chongfu = 0
                this.picList = []
                this.pdfList = []
                this.fileAll = []
                this.uploadQueuePdf = []
                this.uploadQueue = []
                clearInterval(this.allTimer);
                this.allTimer = null;
              }
            }, 1000)
          }else {
            this.chongfuShow = true
            const TIME_COUNT = 5;
            this.time = TIME_COUNT;
            this.timer = setInterval(() => {
              if (this.time > 0 && this.time <= TIME_COUNT) {
                this.time--;
              } else {
                this.chongfuShow = false;
                this.chongfu = 0
                clearInterval(this.timer);
                this.timer = null;
                if(this.uploadQueuePdf.length !=0){
                  this.loading = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "正在提交请稍后..."
                  });
                  this.getpdfUploadOver()
                }else {
                  const loading = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "正在提交请稍后..."
                  });
                  caseSubmit(this.$route.query.caseId).then((res) => {
                    loading.clear()
                    Toast('提交成功');
                   // this.$router.push({path:'/caseList'});
                  })
                }
              }
            }, 1000)
          }
        }else {
          caseSubmit(this.$route.query.caseId).then((res) => {
            toast.clear()
            Toast('提交成功');
           // this.$router.push({path:'/'});
            this.$router.push({path:'/caseList'});
          })
          return;
        }
      }
      let uploadItem = this.fileAll.pop();
      let progressAdd = ()=>{
          setTimeout(()=>{
              progressAdd();
          }, 30);
      };
      progressAdd();
      this.ossUpload( uploadItem, ( state )=>{
          this.startUploadQueue();
      } );
    },
    //上传图片
    ossUpload(file, callBack) {
      // var file = this.files
        const filename = file.name;
        let formData = new FormData();
        var vm = this
        //注意formData里append添加的键的大小写
        formData.append('key', this.dataObj.dir+filename); //存储在oss的文件路径
        formData.append('OSSAccessKeyId', this.dataObj.accessId); //accessKeyId
        formData.append('policy', this.dataObj.policy); //policy
        formData.append('Signature', this.dataObj.signature); //签名
        formData.append('callback', this.dataObj.callback); //回调
        formData.append('success_action_status', 200); //成功后返回的操作码
        formData.append('x-oss-forbid-overwrite', true);
        //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
        formData.append("file", file);
        $.ajax({
          url: this.dataObj.host,
          type: 'POST',
          data: formData,
          // async: false,
          cache: false,
          contentType: false,
          processData: false,
          headers:{
            'x-oss-forbid-overwrite': true
          },
          success: function (data) {
            if(data.code === '2000'){
              callBack( true );
            }else if(data.code === '9007'){
              vm.chongfu++
              callBack( false);
            }else {
              vm.shibai++
              vm.uploadDel(filename)
              callBack( false,);
              alert(filename+'上传失败请稍后再试')
            }
          },
          error: function (data) {
            if(data.status == 409){
              vm.chongfu++
              callBack( false );
            }else {
              vm.shibai++
              vm.uploadDel(filename)
              callBack( false,);
              alert(filename+'上传失败请稍后再试')

            }
          }
        });
      },
    uploadDel(filename){
      delOssPath(this.$route.query.caseId,filename).then((res) => {
      })
    },
    //回显
    getFilter(arr,code){
      if(arr && code){
         return arr.filter(item => item.code === code)[0].name
      }
    },
    //立即投保
    getInsure(){
      rgaInsureUrl(this.$route.query.caseId).then((res) => {
        window.location.href=res
      })
    },
    //取消定时器执行提交
    implement(num){
      if(num == '5'){
        this.chongfuShow = false;
        this.chongfu = 0
        clearInterval(this.timer);
        this.timer = null;
        if(this.uploadQueuePdf.length !=0){
          this.loading = Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "正在提交请稍后..."
          });
          this.getpdfUploadOver()
        }else {
          const loading = Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "正在提交请稍后..."
          });
          caseSubmit(this.$route.query.caseId).then((res) => {
            loading.clear()
            Toast('提交成功');
            // this.$router.push({path:'/caseList'});
          })
        }
      }else {
        this.chongfuAll = false;
        this.chongfu = 0
        this.picList = []
        this.pdfList = []
        this.fileAll = []
        this.uploadQueuePdf = []
        this.uploadQueue = []
        clearInterval(this.allTimer);
        this.allTimer = null;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/bg.jpg");
  background-size: 100% 100%;
  position: fixed;
  font-size: .24rem;
  overflow-y: scroll;
  .examine {
    .examine-top {
      font-weight: bold;
      text-align: center;
      padding: 1rem 0;
      img {
        width: .78rem;
        height: .56rem;
        margin-bottom: .3rem;
      }
    }
    .examine-bot {
      margin: 0 .3rem;
      .title {
        font-weight: bold;
        margin-bottom: .3rem;
        img {
          width: .24rem;
          height: .24rem;
          float: left;
          margin-right: .1rem;
          margin-top: .05rem;
        }
      }
      .box {
        background: #ffffff;
        box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
        border-radius: 8px;
        padding: .3rem;
        line-height: .48rem;
        font-weight: bold;
        .refresh {
          width: 100%;
          text-align: center;
          color: #0068ff;
          font-weight: bold;
          line-height: .6rem;
          display: inline-block;
          margin: 0 auto;
          img {
            width: .24rem;
            height: .24rem;
            display: inline-block;
            vertical-align: middle;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .result-top {
    padding: .5rem .8rem;
    .result-img {
      width: 100%;
      text-align: center;
      margin-bottom: .3rem;
    }
    .tips {
      line-height: .4rem;
      p {
        font-weight: bold;
      }
    }
  }
  .result-bot {
    margin: 0 .3rem;
    .title {
      font-weight: bold;
      margin-bottom: .3rem;
      img {
        width: .24rem;
        height: .24rem;
        float: left;
        margin-right: 5px;
      }
    }
    .box {
      background: #ffffff;
      box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
      border-radius: 8px;
      padding: .1rem .3rem;
      line-height: .48rem;
    }
    .tab-box {
      background: #ffffff;
      box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
      border-radius: 8px;
      line-height: .48rem;
      margin-bottom: .4rem;
      .title {
        font-weight: bold;
        padding:.2rem .3rem;
        margin: 0;
      }
      table {
        th {
          background: #f1f8ff;
          line-height: 32px;
        }
        td {
          border: 1px solid rgba(146, 157, 163, 0.04);
          padding: .1rem 0;
          line-height: .4rem;
          table{
            td{
              padding: 0;
            }
          }
        }
        tr {
          :first-child {
            padding-left: .3rem;
          }
        }
      }
      .tab-list {
        width: 100%;
        display: flex;
        line-height: .7rem;
        .tab-list-left {
          width: 30%;
        }
        .tab-list-right {
          width: 70%;
        }
        .span-list {
          display: inline-block;
          margin-right: .6rem;
        }
      }
    }
    .check {
      text-align: center;
      line-height: .6rem;
    }
  }
  .list {
    .list-title {
      font-weight: bold;
      color: #0068ff;
    }
    .list-box {
      .basic {
        display: flex;
        border-bottom: 1px solid rgba(146, 157, 163, 0.04);
        padding-top: .15rem;
        padding-bottom: .15rem;
        div {
          width: 50%;
        }
      }
      .details {
        border-bottom: 1px solid rgba(146, 157, 163, 0.04);
        padding: .15rem 0;
        justify-content: space-between;
        flex-wrap: wrap;
        span {
        //  margin-right: .4rem;
        //  float: right;
        }
        .span-right{
          float: right;
        }
        i{
          font-style:normal
        }
      }
      :last-child {
        border: none;
      }
    }
  }
  .btn {
    width: 100%;
    height: 56px;
    background: #0068ff;
    color: #fff;
    text-align: center;
    line-height: 56px;
    font-weight: bold;
    position: fixed;
    left: 0;
    bottom: 0;
    font-size: 14px;
  }
  .font-red {
    color: #ff0000;
    font-weight: bold;
  }
  .font-bule {
    color: #0068ff;
    font-weight: bold;
  }
  .margin-top-15 {
    margin-top: .3rem;
  }
}
.buchong {
  margin: .3rem;
  .buchong-title {
    width: 1.38rem;
    height: .64rem;
    background: #0068ff;
    border-radius: 4px 4px 0px 0px;
    text-align: center;
    line-height: .64rem;
    color: #fff;
  }
  .buchong-box {
    padding: .3rem;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.44) 0%,
        #ffffff 100%
    );
    box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
  }
}
.jubao {
  margin: .3rem;
}
.jubao-btn {
  width: 1.7rem;
  height: .72rem;
  background: #0068ff;
  border-radius: .48rem;
  text-align: center;
  color: #fff;
  margin: .3rem auto;
  line-height: .72rem;
}
.layer-box{
  background: #fff;
  margin: .4rem;
  border-radius: 4px;
  padding: .4rem;
  .layer-title{
    text-align: center;
    line-height: .6rem;

  }
}
.layer-btn-box{
  display: flex;
   flex-direction: row;
  justify-content: center;
  align-items: center;
  .layer-btn{
    width: 1rem;
    height: .44rem;
    background: #C4C4C4;
    border-radius: .48rem;
    text-align: center;
    line-height: .44rem;
    color: #fff;
    margin: .3rem;
  }
  .bg-blue{
    background: #0068FF;
  }
}
.bc-btn{
  width: 1.7rem;
  height: .7rem;
  background: #0068FF;
  border-radius: .48rem;
  text-align: center;
  line-height: .72rem;
  font-size: .26rem;
  color: #fff;
  margin: .1rem auto;
}
.modify{
  font-size: .14rem;
  color: #0068FF;
  float: right;
}
</style>

