var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "basics-bot" },
      [
        _c(
          "van-form",
          [
            _c("div", { staticClass: "wh-box line" }, [
              _c("div", { staticStyle: { position: "relative" } }, [
                _c("span", { staticClass: "font-red" }, [_vm._v("*")]),
                _vm._v(" 姓名 ")
              ]),
              _c(
                "div",
                [
                  _c("van-field", {
                    attrs: {
                      name: "姓名",
                      label: "",
                      placeholder: "请输入姓名",
                      "input-align": "right",
                      disabled: ""
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "wh-box line" }, [
              _c("div", { staticStyle: { position: "relative" } }, [
                _c("span", { staticClass: "font-red" }, [_vm._v("*")]),
                _vm._v(" 出生日期 ")
              ]),
              _c(
                "div",
                [
                  _c("van-field", {
                    attrs: {
                      readonly: "",
                      clickable: "",
                      name: "picker",
                      value: _vm.birthday,
                      label: "",
                      "input-align": "right",
                      placeholder: "请选择出生日期"
                    },
                    on: {
                      click: function($event) {
                        _vm.showBirthday = true
                      }
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "van-popup",
              {
                attrs: { position: "bottom" },
                model: {
                  value: _vm.showBirthday,
                  callback: function($$v) {
                    _vm.showBirthday = $$v
                  },
                  expression: "showBirthday"
                }
              },
              [
                _c("van-datetime-picker", {
                  attrs: {
                    type: "date",
                    title: "选择年月日",
                    "min-date": _vm.minDate,
                    "max-date": _vm.maxDate
                  },
                  on: {
                    confirm: _vm.getBirthday,
                    cancel: function($event) {
                      _vm.showBirthday = false
                    }
                  },
                  model: {
                    value: _vm.currentDate,
                    callback: function($$v) {
                      _vm.currentDate = $$v
                    },
                    expression: "currentDate"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "wh-box line" }, [
              _c("div", { staticStyle: { position: "relative" } }, [
                _c("span", { staticClass: "font-red" }, [_vm._v("*")]),
                _vm._v(" 有无社保 ")
              ]),
              _c(
                "div",
                [
                  _c("pickerPopup", {
                    attrs: {
                      columns: _vm.shebaoValue,
                      title: "",
                      val: _vm.ruleForm.isSocialSecurity
                    },
                    on: { childByValue: _vm.getIsSocialSecurity }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                staticClass: "wh-box line",
                staticStyle: { position: "relative" }
              },
              [
                _c("div", { staticStyle: { width: "65%" } }, [
                  _c("span", { staticClass: "font-red" }, [_vm._v("*")]),
                  _vm._v(" 是否新辅助治疗 ")
                ]),
                _c(
                  "div",
                  [
                    _c("pickerPopup", {
                      attrs: {
                        columns: _vm.xuanzeValue,
                        title: "",
                        val: _vm.ruleForm.isCompTherapy
                      },
                      on: { childByValue: _vm.getIsCompTherapy }
                    })
                  ],
                  1
                )
              ]
            ),
            _c("div", { staticClass: "line" }, [
              _c("div", { staticClass: "wh-box" }, [
                _c("div", { staticStyle: { position: "relative" } }, [
                  _c("span", { staticClass: "font-red" }, [_vm._v("*")]),
                  _vm._v(" 保额 ")
                ]),
                _c(
                  "div",
                  [
                    _c("pickerPopup", {
                      attrs: {
                        columns: _vm.initList.insuredSum,
                        title: "",
                        val: _vm.ruleForm.insuredSum
                      },
                      on: { childByValue: _vm.getInsuredSum }
                    })
                  ],
                  1
                )
              ]),
              _vm.ruleForm.insuredSum == "150"
                ? _c("div", { staticClass: "bule-zhu" }, [
                    _vm._v(
                      "保额含：社保内限额50万、社保外限额50万、特定恶性肿瘤药品限额50万"
                    )
                  ])
                : _vm._e(),
              _vm.ruleForm.insuredSum == "10"
                ? _c("div", { staticClass: "bule-zhu" }, [
                    _vm._v("保额含：社保内限额10万")
                  ])
                : _vm._e(),
              _vm.ruleForm.insuredSum == "40"
                ? _c("div", { staticClass: "bule-zhu" }, [
                    _vm._v(
                      "保额含：社保内限额20万、社保外限额10万、特定恶性肿瘤药品限额10万"
                    )
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "wh-box line" }, [
              _c(
                "div",
                { staticStyle: { width: "70%", "padding-top": ".2rem" } },
                [
                  _c("span", { staticClass: "font-red" }, [_vm._v("*")]),
                  _vm._v(" 社保范围内的自付费用赔付比例 ")
                ]
              ),
              _c(
                "div",
                [
                  _c("pickerPopup", {
                    attrs: {
                      columns: _vm.initList.compensateRatioIn,
                      title: "",
                      zifu: _vm.zifu,
                      val: _vm.ruleForm.inSocialPercentage
                    },
                    on: { childByValue: _vm.getInSocialPercentage }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "fw line" }, [
              _vm._v("社保范围外的自费费用赔付比例"),
              _vm.ruleForm.outSocialPercentage != ""
                ? _c("span", [
                    _vm._v(_vm._s(_vm.ruleForm.outSocialPercentage) + "%")
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "wh-box" }, [
              _c("div", { staticStyle: { width: "70%" } }, [
                _c("span", { staticClass: "font-red" }, [_vm._v("*")]),
                _vm._v(" 重大疾病保险金1万(可选) ")
              ]),
              _c(
                "div",
                [
                  _c("pickerPopup", {
                    attrs: {
                      columns: _vm.xuanzeValue,
                      title: "",
                      val: _vm.ruleForm.isSeriousIllAmount1W
                    },
                    on: { childByValue: _vm.getIsSeriousIllAmount1W }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }