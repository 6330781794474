var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("goback", { attrs: { msg: _vm.msg, url: "/" } }),
      _vm.examineShow
        ? _c("div", { staticClass: "examine" }, [
            _vm._m(0),
            _c("div", { staticClass: "examine-bot" }, [
              _vm._m(1),
              _c("div", { staticClass: "box" }, [
                _c("div", [
                  _c("span", { staticClass: "font-red" }, [_vm._v("Q")]),
                  _vm._v(" " + _vm._s(_vm.q))
                ]),
                _c("div", [
                  _c("span", { staticClass: "font-bule" }, [_vm._v("A")]),
                  _vm._v(" " + _vm._s(_vm.a) + " ")
                ]),
                _c(
                  "div",
                  { staticClass: "refresh", on: { click: _vm.getQuestion } },
                  [
                    _c("img", {
                      attrs: { src: require("../../assets/images/hyh.png") }
                    }),
                    _vm._v("换一换")
                  ]
                )
              ])
            ])
          ])
        : _c("div", [
            _vm.result == "02" && !this.$route.query.supplement
              ? _c("div", { staticClass: "result-top" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "tips" }, [
                    _c("p", [
                      _vm._v("很抱歉，您当前的情况不符合投保规则，不接受投保。")
                    ]),
                    _vm.resultMessage != ""
                      ? _c("p", [_vm._v("原因：" + _vm._s(_vm.resultMessage))])
                      : _vm._e(),
                    _c("div", { staticClass: "font-red margin-top-15" }, [
                      _vm._v(
                        "注：本结果的出具仅基于您当前已提供的部分信息，保险公司拥有正式核保决定及最终解释权。"
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.result == "01"
              ? _c("div", { staticClass: "result-top" }, [
                  _vm._m(3),
                  _c("div", { staticClass: "tips" }, [
                    _c("p", [
                      _vm._v(
                        "恭喜您，根据您提供的信息，初步判定您基本符合投保条件，请点击页面底部链接进行投保"
                      )
                    ]),
                    !_vm.isOptionalLiability && !_vm.$route.query.status
                      ? _c("p", [
                          _vm._v(
                            "重大疾病保险金（可选）：不接受，仅能投保基本责任"
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "font-red margin-top-15" }, [
                      _vm._v(
                        "注：本结果的出具仅基于您当前已提供的部分信息，xx保险公司拥有正式核保决定及最终解释权。"
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.result == "03" ||
            _vm.result == "05" ||
            this.$route.query.supplement
              ? _c("div", { staticClass: "result-top" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "tips" }, [
                    _c("p", [
                      _vm._v("当前信息不足，请您补充对应资料后再行评估。")
                    ]),
                    _c(
                      "div",
                      [
                        _vm._v(" 需补充资料类型: "),
                        _vm._l(_vm.lackInformationList, function(item, index) {
                          return _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [
                              _vm._v(" " + _vm._s(item)),
                              _vm.lackInformationList.length - 1 != index
                                ? _c("i", [_vm._v("、")])
                                : _vm._e()
                            ]
                          )
                        }),
                        _vm.lackInformationList.length != 0
                          ? _c("span", [
                              _vm._v("，" + _vm._s(_vm.resultMessage))
                            ])
                          : _c("span", [_vm._v(_vm._s(_vm.resultMessage))])
                      ],
                      2
                    )
                  ])
                ])
              : _vm._e(),
            _vm.result == "04"
              ? _c("div", { staticClass: "result-top" }, [
                  _vm._m(5),
                  _c("div", { staticClass: "tips" }, [
                    _c("p", [
                      _vm._v("很抱歉，您当前的情况不符合投保规则，不接受投保。")
                    ]),
                    _vm.resultMessage != ""
                      ? _c("p", [_vm._v("原因：" + _vm._s(_vm.resultMessage))])
                      : _vm._e(),
                    _c("div", { staticClass: "font-red margin-top-15" }, [
                      _vm._v(
                        "注：本结果的出具仅基于您当前已提供的部分信息，xx保险公司拥有正式核保决定及最终解释权。"
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.result == "03" ||
            _vm.result == "05" ||
            this.$route.query.supplement
              ? _c("div", { staticClass: "buchong" }, [
                  _c("div", { staticClass: "buchong-title" }, [
                    _vm._v("补充上传")
                  ]),
                  _c(
                    "div",
                    { staticClass: "buchong-box" },
                    [
                      _c("upload", {
                        attrs: { picList: _vm.picList, pdfList: _vm.pdfList },
                        on: {
                          getFiles: _vm.getFiles,
                          getFilespdf: _vm.getFilespdf
                        }
                      }),
                      _c(
                        "div",
                        [
                          _vm._v("请您尽量提供齐全的资料，包括但不限于： "),
                          _vm._l(_vm.lackInformationList, function(
                            item,
                            index
                          ) {
                            return _c("span", [
                              _vm._v(_vm._s(item)),
                              _vm.lackInformationList.length - 1 != index
                                ? _c("i", [_vm._v("、")])
                                : _vm._e()
                            ])
                          })
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "bc-btn",
                          on: { click: _vm.getSignature }
                        },
                        [_vm._v("提交")]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c("div", { staticClass: "result-bot" }, [
              _vm.plan
                ? _c("div", { staticClass: "title" }, [
                    _c("img", {
                      attrs: { src: require("../../assets/images/bxjh.png") }
                    }),
                    _vm._v("保险计划 ")
                  ])
                : _vm._e(),
              _vm.plan
                ? _c(
                    "div",
                    { staticClass: "tab-box" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "根据您选择的保额及赔付比例，已匹配为" +
                            _vm._s(_vm.plan.name) +
                            "："
                        )
                      ]),
                      _c(
                        "table",
                        {
                          attrs: {
                            width: "100%",
                            cellspacing: "0",
                            border: "1px"
                          }
                        },
                        [
                          _vm._m(6),
                          _c("tr", [
                            _c(
                              "td",
                              { staticStyle: { "font-weight": "bold" } },
                              [_vm._v("乳腺癌首次复发医疗保险金限额")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  "font-weight": "bold"
                                },
                                attrs: { colspan: "2" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.plan.other.passResultExcel.startingLimit
                                  )
                                )
                              ]
                            )
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v("社保范围内的自付和个人首先自付费用")
                            ]),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  width: "20%"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.plan.other.passResultExcel
                                      .inSocialSelfCostLimit
                                  )
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  width: "20%"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.plan.other.passResultExcel
                                      .inSocialSelfPercentage
                                  )
                                )
                              ]
                            )
                          ]),
                          _c("tr", [
                            _c("td", [_vm._v("社保范围外的自费费用")]),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  width: "20%"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.plan.other.passResultExcel
                                      .outSocialSelfCostLimit
                                  )
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  width: "20%"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.plan.other.passResultExcel
                                      .outSocialSelfPercentage
                                  )
                                )
                              ]
                            )
                          ]),
                          _c("tr", [
                            _c("td", [_vm._v("特定恶性肿瘤药品费用")]),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  width: "20%"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.plan.other.passResultExcel
                                      .tumorDrugCostLimit
                                  )
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  width: "20%"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.plan.other.passResultExcel
                                      .tumorDrugPercentage
                                  )
                                )
                              ]
                            )
                          ]),
                          _vm.caseDetail.isSeriousIllAmount1W
                            ? _c("tr", [
                                _c(
                                  "td",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [_vm._v("重大疾病保险金金额")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "font-weight": "bold"
                                    },
                                    attrs: { colspan: "2" }
                                  },
                                  [_vm._v("定额1万元")]
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm.yearCharge.firstYear
                        ? _c(
                            "table",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: {
                                width: "100%",
                                cellspacing: "0",
                                border: "1px"
                              }
                            },
                            [
                              _vm._m(7),
                              _vm.yearCharge.firstYear
                                ? _c("tr", [
                                    _c("td", [_vm._v("首期年缴保费")]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.yearCharge.firstYear.withOptional
                                        )
                                      )
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.yearCharge.firstYear
                                            .withoutOptional
                                        )
                                      )
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.yearCharge.firstYear.ageGroup
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._l(_vm.yearCharge.followingYear, function(
                                item,
                                index
                              ) {
                                return _c("tr", [
                                  index == "0"
                                    ? _c(
                                        "td",
                                        {
                                          attrs: {
                                            rowspan:
                                              _vm.yearCharge.followingYear
                                                .length
                                          }
                                        },
                                        [_vm._v("后续年缴保费")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "td",
                                    { staticStyle: { "padding-left": "0" } },
                                    [_vm._v(_vm._s(item.withOptional))]
                                  ),
                                  _c("td", [
                                    _vm._v(_vm._s(item.withoutOptional))
                                  ]),
                                  _c("td", [_vm._v(_vm._s(item.ageGroup))])
                                ])
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.isIhcWarning
                        ? _c(
                            "div",
                            { staticStyle: { padding: ".15rem .2rem" } },
                            [
                              _c("van-icon", {
                                attrs: { name: "warning-o", color: "#ff0000" }
                              }),
                              _vm._v(
                                " 免疫组化与分型不一致，费率无法明确，请核实免疫组化及分型是否填写正确！建议上传病历由专业核保师协助处理。 "
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "font-bule check",
                          on: {
                            click: function($event) {
                              _vm.layer = true
                            }
                          }
                        },
                        [_vm._v("若想调整保险计划或基本信息，可点击此处修改")]
                      ),
                      _c(
                        "van-overlay",
                        {
                          attrs: { show: _vm.layer },
                          on: {
                            click: function($event) {
                              _vm.layer = false
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "wrapper",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "layer-box" },
                                [
                                  _c("div", { staticClass: "layer-title" }, [
                                    _vm._v("信息修改")
                                  ]),
                                  _c("basics", {
                                    attrs: {
                                      caseDetail: _vm.caseDetail,
                                      getname: _vm.name,
                                      getbirthday: _vm.birthday,
                                      initList: _vm.initList
                                    },
                                    on: {
                                      getBasics: _vm.getBasics,
                                      getNewName: _vm.getNewName,
                                      getNewBirthday: _vm.getNewBirthday
                                    }
                                  }),
                                  _c("div", { staticClass: "layer-btn-box" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "layer-btn",
                                        on: {
                                          click: function($event) {
                                            _vm.layer = false
                                          }
                                        }
                                      },
                                      [_vm._v(" 取消")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "layer-btn bg-blue",
                                        on: { click: _vm.onSubmit }
                                      },
                                      [_vm._v("提交")]
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _vm.totalImageCount && _vm.totalImageCount != ""
              ? _c("div", { staticClass: "result-bot" }, [
                  _vm._m(8),
                  _c("div", { staticClass: "box" }, [
                    _vm.totalImageCount
                      ? _c("div", { staticClass: "summary" }, [
                          _c("p", [
                            _vm._v("提供资料总计"),
                            _c("span", { staticClass: "font-red" }, [
                              _vm._v(_vm._s(_vm.totalImageCount))
                            ]),
                            _vm._v("页（重复图像未计）:")
                          ]),
                          _c(
                            "p",
                            _vm._l(_vm.imageInfoMap, function(item, index) {
                              return _c("span", [
                                _vm._v(
                                  _vm._s(_vm.getImgName(item.informationType))
                                ),
                                _c("span", { staticClass: "font-red" }, [
                                  _vm._v(_vm._s(item.informationCount))
                                ]),
                                _vm._v("页"),
                                _vm.imageInfoMap.length - 1 !== index
                                  ? _c("i", [_vm._v("、")])
                                  : _vm._e()
                              ])
                            }),
                            0
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "font-bule check",
                              on: { click: _vm.jump }
                            },
                            [_vm._v("查看我已上传的图像")]
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "list" }, [
                      _c("div", { staticClass: "list-title" }, [
                        _vm._v(" 基本信息摘要 ")
                      ]),
                      _c("div", { staticClass: "list-box" }, [
                        _vm.basicSummary.name &&
                        _vm.basicSummary.name.length != 0
                          ? _c("div", { staticClass: "basic" }, [
                              _vm.basicSummary.name &&
                              _vm.basicSummary.name.length != 0
                                ? _c(
                                    "div",
                                    [
                                      _vm._v("姓名:"),
                                      _vm._l(_vm.basicSummary.name, function(
                                        item
                                      ) {
                                        return _c("span", [
                                          _vm._v(_vm._s(item))
                                        ])
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.basicSummary.gender &&
                              _vm.basicSummary.gender.length != 0
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _vm._v("性别："),
                                      _vm._l(_vm.basicSummary.gender, function(
                                        item
                                      ) {
                                        return _c("span", [
                                          _vm._v(_vm._s(item))
                                        ])
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ])
                          : _c("div", [
                              _vm.basicSummary.name &&
                              _vm.basicSummary.name.length != 0
                                ? _c(
                                    "div",
                                    [
                                      _vm._v("姓名:"),
                                      _vm._l(_vm.basicSummary.name, function(
                                        item
                                      ) {
                                        return _c("span", [
                                          _vm._v(_vm._s(item))
                                        ])
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.basicSummary.gender &&
                              _vm.basicSummary.gender.length != 0
                                ? _c(
                                    "div",
                                    [
                                      _vm._v("性别："),
                                      _vm._l(_vm.basicSummary.gender, function(
                                        item
                                      ) {
                                        return _c("span", [
                                          _vm._v(_vm._s(item))
                                        ])
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]),
                        _c("div", { staticClass: "basic" }, [
                          _vm.basicSummary.age &&
                          _vm.basicSummary.age.length != 0
                            ? _c(
                                "div",
                                [
                                  _vm._v("年龄："),
                                  _vm._l(_vm.basicSummary.age, function(item) {
                                    return _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-right": ".15rem"
                                        }
                                      },
                                      [_vm._v(_vm._s(item))]
                                    )
                                  })
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.basicSummary.birthday &&
                          _vm.basicSummary.birthday.length != 0
                            ? _c(
                                "div",
                                [
                                  _vm._v("出生年月："),
                                  _vm._l(_vm.basicSummary.birthday, function(
                                    item
                                  ) {
                                    return _c("span", [_vm._v(_vm._s(item))])
                                  })
                                ],
                                2
                              )
                            : _vm._e()
                        ])
                      ]),
                      _vm.hospitalSummary
                        ? _c("div", { staticClass: "list-title" }, [
                            _vm._v(" 就诊摘要 ")
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "list-box" },
                        _vm._l(_vm.hospitalSummary, function(item) {
                          return _c("div", { staticClass: "details" }, [
                            item.time && item.time.length != 0
                              ? _c(
                                  "div",
                                  [
                                    _vm._v(" 就诊时间："),
                                    _vm._l(item.time, function(ite) {
                                      return _c(
                                        "span",
                                        { staticClass: "span-right" },
                                        [_vm._v(_vm._s(ite))]
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            item.diagnose && item.diagnose.length != 0
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between",
                                      "flex-wrap": "wrap"
                                    }
                                  },
                                  [
                                    _c("div", [_vm._v(" 诊断： ")]),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "70%",
                                          "text-align": "right"
                                        }
                                      },
                                      _vm._l(item.diagnose, function(
                                        ite,
                                        index
                                      ) {
                                        return _c("span", [
                                          _vm._v(_vm._s(ite)),
                                          item.diagnose.length - 1 != index
                                            ? _c("i", [_vm._v("、")])
                                            : _vm._e()
                                        ])
                                      }),
                                      0
                                    )
                                  ]
                                )
                              : _vm._e(),
                            item.clinicComp && item.clinicComp.length != 0
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between",
                                      "flex-wrap": "wrap"
                                    }
                                  },
                                  [
                                    _c("div", [_vm._v("是否新辅助治疗：")]),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "55%",
                                          "text-align": "right"
                                        }
                                      },
                                      _vm._l(item.clinicComp, function(
                                        ite,
                                        index
                                      ) {
                                        return _c("span", [
                                          _vm._v(_vm._s(ite)),
                                          item.clinicComp.length - 1 != index
                                            ? _c("i", [_vm._v("、")])
                                            : _vm._e()
                                        ])
                                      }),
                                      0
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        }),
                        0
                      ),
                      _c("div", { staticClass: "list-title" }, [
                        _vm._v(" 其他关键摘要 ")
                      ]),
                      _c("div", { staticClass: "list-box" }, [
                        _vm.otherSummary.staging &&
                        _vm.otherSummary.staging.length != 0
                          ? _c(
                              "div",
                              {
                                staticClass: "details",
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "flex-wrap": "wrap"
                                }
                              },
                              [
                                _c("div", [_vm._v("分期：")]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "70%",
                                      "text-align": "right"
                                    }
                                  },
                                  _vm._l(_vm.otherSummary.staging, function(
                                    item,
                                    index
                                  ) {
                                    return _c("span", [
                                      _vm._v(_vm._s(item)),
                                      _vm.otherSummary.staging.length - 1 !=
                                      index
                                        ? _c("i", [_vm._v("、")])
                                        : _vm._e()
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.otherSummary.typing &&
                        _vm.otherSummary.typing.length != 0
                          ? _c(
                              "div",
                              {
                                staticClass: "details",
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "flex-wrap": "wrap"
                                }
                              },
                              [
                                _c("div", [_vm._v("分型：")]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "70%",
                                      "text-align": "right"
                                    }
                                  },
                                  _vm._l(_vm.otherSummary.typing, function(
                                    item,
                                    index
                                  ) {
                                    return _c("span", [
                                      _vm._v(_vm._s(item)),
                                      _vm.otherSummary.typing.length - 1 !=
                                      index
                                        ? _c("i", [_vm._v("、")])
                                        : _vm._e()
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.otherSummary.operationHospital &&
                        _vm.otherSummary.operationHospital.length != 0
                          ? _c(
                              "div",
                              {
                                staticClass: "details",
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "flex-wrap": "wrap"
                                }
                              },
                              [
                                _c("div", [_vm._v("手术医院：")]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "70%",
                                      "text-align": "right"
                                    }
                                  },
                                  _vm._l(
                                    _vm.otherSummary.operationHospital,
                                    function(item, index) {
                                      return _c("span", [
                                        _vm._v(_vm._s(item)),
                                        _vm.otherSummary.operationHospital
                                          .length -
                                          1 !=
                                        index
                                          ? _c("i", [_vm._v("、")])
                                          : _vm._e()
                                      ])
                                    }
                                  ),
                                  0
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.operations.length != 0
                          ? _c(
                              "div",
                              _vm._l(_vm.operations, function(item) {
                                return _c("div", { staticClass: "details" }, [
                                  item.time && item.time.length != 0
                                    ? _c(
                                        "div",
                                        [
                                          _vm._v("手术时间："),
                                          _vm._l(item.time, function(ite) {
                                            return _c("span", [
                                              _vm._v(_vm._s(ite))
                                            ])
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  item.operationName &&
                                  item.operationName.length != 0
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "space-between",
                                            "flex-wrap": "wrap"
                                          }
                                        },
                                        [
                                          _c("div", [_vm._v("手术名称：")]),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "70%",
                                                "text-align": "right"
                                              }
                                            },
                                            _vm._l(item.operationName, function(
                                              ite,
                                              index
                                            ) {
                                              return _c("span", [
                                                _vm._v(_vm._s(ite)),
                                                item.operationName.length - 1 !=
                                                index
                                                  ? _c("i", [_vm._v("、")])
                                                  : _vm._e()
                                              ])
                                            }),
                                            0
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm.otherSummary.tumorSize &&
                        _vm.otherSummary.tumorSize.length != 0
                          ? _c(
                              "div",
                              {
                                staticClass: "details",
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "flex-wrap": "wrap"
                                }
                              },
                              [
                                _c("div", [_vm._v(" 肿物大小：")]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "70%",
                                      "text-align": "right"
                                    }
                                  },
                                  _vm._l(_vm.otherSummary.tumorSize, function(
                                    item,
                                    index
                                  ) {
                                    return _c("span", [
                                      _vm._v(_vm._s(item)),
                                      _vm.otherSummary.tumorSize.length - 1 !=
                                      index
                                        ? _c("i", [_vm._v("、")])
                                        : _vm._e()
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.otherSummary.cuttingEdge &&
                        _vm.otherSummary.cuttingEdge.length != 0
                          ? _c(
                              "div",
                              {
                                staticClass: "details",
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "flex-wrap": "wrap"
                                }
                              },
                              [
                                _c("div", [_vm._v(" 切缘：")]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "70%",
                                      "text-align": "right"
                                    }
                                  },
                                  _vm._l(_vm.otherSummary.cuttingEdge, function(
                                    item
                                  ) {
                                    return _c("i", [_vm._v(_vm._s(item))])
                                  }),
                                  0
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.otherSummary.regionalLymphNode &&
                        _vm.otherSummary.regionalLymphNode.length != 0
                          ? _c(
                              "div",
                              {
                                staticClass: "details",
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "flex-wrap": "wrap"
                                }
                              },
                              [
                                _c("div", [_vm._v("区域淋巴结：")]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "70%",
                                      "text-align": "right"
                                    }
                                  },
                                  _vm._l(
                                    _vm.otherSummary.regionalLymphNode,
                                    function(item, index) {
                                      return _c("span", [_vm._v(_vm._s(item))])
                                    }
                                  ),
                                  0
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.otherSummary.pathologicDiagnosis &&
                        _vm.otherSummary.pathologicDiagnosis.length != 0
                          ? _c(
                              "div",
                              {
                                staticClass: "details",
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "flex-wrap": "wrap"
                                }
                              },
                              [
                                _c("div", [_vm._v("病理诊断：")]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "70%",
                                      "text-align": "right"
                                    }
                                  },
                                  _vm._l(
                                    _vm.otherSummary.pathologicDiagnosis,
                                    function(item, index) {
                                      return _c("span", [
                                        _vm._v(_vm._s(item)),
                                        _vm.otherSummary.pathologicDiagnosis
                                          .length -
                                          1 !=
                                        index
                                          ? _c("i", [_vm._v("、")])
                                          : _vm._e()
                                      ])
                                    }
                                  ),
                                  0
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "result-bot",
                staticStyle: { "margin-top": ".4rem" }
              },
              [
                _c("div", { staticClass: "title" }, [
                  _c("img", {
                    attrs: { src: require("../../assets/images/blzy.png") }
                  }),
                  _vm._v("已填信息 "),
                  this.$route.query.status == "04"
                    ? _c(
                        "span",
                        {
                          staticClass: "modify",
                          on: { click: _vm.jumpUpload }
                        },
                        [_vm._v("填写有误，返回修改")]
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "list" }, [
                    _c("div", { staticClass: "list-box" }, [
                      _c("div", { staticClass: "basic" }, [
                        _c("div", [
                          _vm._v("姓名:"),
                          _c("span", [_vm._v(_vm._s(_vm.name))])
                        ]),
                        _c("div", { staticStyle: { "text-align": "right" } }, [
                          _vm._v("出生年月："),
                          _c("span", [_vm._v(_vm._s(_vm.birthday))])
                        ])
                      ]),
                      _c("div", { staticClass: "details" }, [
                        _vm._v(" 有无社保："),
                        _c("span", { staticClass: "span-right" }, [
                          _vm._v(
                            _vm._s(_vm.getYorY(_vm.caseDetail.isSocialSecurity))
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "details" }, [
                        _vm._v(" 是否新辅助治疗："),
                        _c("span", { staticClass: "span-right" }, [
                          _vm._v(
                            _vm._s(_vm.getYorN(_vm.caseDetail.isCompTherapy))
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "details" }, [
                        _vm._v(" 保额："),
                        _c("span", { staticClass: "span-right" }, [
                          _vm._v(_vm._s(_vm.caseDetail.insuredSum) + "万")
                        ])
                      ]),
                      _c("div", { staticClass: "details" }, [
                        _vm._v(" 社保范围内的自付费用赔付比例："),
                        _c("span", { staticClass: "span-right" }, [
                          _vm._v(
                            _vm._s(_vm.caseDetail.inSocialPercentage) + "%"
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "details" }, [
                        _vm._v(" 社保范围外的自费费用赔付比例："),
                        _c("span", { staticClass: "span-right" }, [
                          _vm._v(
                            _vm._s(_vm.caseDetail.outSocialPercentage) + "%"
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "details" }, [
                        _vm._v(" 重大疾病保险金1万(可选)："),
                        _vm.caseDetail.isSeriousIllAmount1W
                          ? _c("span", { staticClass: "span-right" }, [
                              _vm._v("投保")
                            ])
                          : _c("span", { staticClass: "span-right" }, [
                              _vm._v("不投保")
                            ])
                      ])
                    ]),
                    _c("div", { staticClass: "list-box" }, [
                      _vm.quezhen && _vm.quezhen.length != 0
                        ? _c(
                            "div",
                            {
                              staticClass: "details",
                              staticStyle: { display: "flex" }
                            },
                            [
                              _c("div", [_vm._v("已确诊:")]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "80%",
                                    "margin-left": "20px",
                                    "text-align": "right"
                                  }
                                },
                                _vm._l(_vm.quezhen, function(item, index) {
                                  return _c("i", [
                                    _vm._v(_vm._s(item)),
                                    _vm.quezhen.length - 1 !== index
                                      ? _c("i", [_vm._v("、")])
                                      : _vm._e()
                                  ])
                                }),
                                0
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.shoushu && _vm.shoushu.length != 0
                        ? _c(
                            "div",
                            {
                              staticClass: "details",
                              staticStyle: { display: "flex" }
                            },
                            [
                              _c("div", [_vm._v("已手术: ")]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "80%",
                                    "margin-left": "20px",
                                    "text-align": "right"
                                  }
                                },
                                _vm._l(_vm.shoushu, function(item, index) {
                                  return _c("i", [
                                    _vm._v(_vm._s(item)),
                                    _vm.shoushu.length - 1 !== index
                                      ? _c("i", [_vm._v("、")])
                                      : _vm._e()
                                  ])
                                }),
                                0
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.caseDetail.cutEdgeResults
                        ? _c("div", { staticClass: "details" }, [
                            _c("div", [
                              _vm._v("切缘: "),
                              _c("span", { staticStyle: { float: "right" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getFilter(
                                      this.initList.cutEdgeResults,
                                      this.caseDetail.cutEdgeResults
                                    )
                                  )
                                )
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm.caseDetail.pathologyTyping
                        ? _c("div", { staticClass: "details" }, [
                            _c("div", [
                              _vm._v("分期:"),
                              _c("span", { staticClass: "span-right" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getFilter(
                                      this.initList.pathologyTyping,
                                      this.caseDetail.pathologyTyping
                                    )
                                  )
                                )
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm.caseDetail.ihcEr ||
                      _vm.caseDetail.ihcPr ||
                      _vm.caseDetail.ihcKi67 ||
                      _vm.caseDetail.ihcHer2 ||
                      _vm.caseDetail.ihcFish
                        ? _c("div", { staticClass: "details" }, [
                            _c("div", [_vm._v("免疫组化")])
                          ])
                        : _vm._e(),
                      _vm.caseDetail.ihcEr
                        ? _c("div", { staticClass: "details" }, [
                            _vm._v("ER:"),
                            _c("span", { staticClass: "span-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getFilter(
                                    this.initList.ihcErType,
                                    this.caseDetail.ihcEr
                                  )
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.caseDetail.ihcPr
                        ? _c("div", { staticClass: "details" }, [
                            _vm._v("PR:"),
                            _c("span", { staticClass: "span-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getFilter(
                                    this.initList.ihcPrType,
                                    this.caseDetail.ihcPr
                                  )
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.caseDetail.ihcKi67
                        ? _c("div", { staticClass: "details" }, [
                            _vm._v("Ki-67:"),
                            _c("span", { staticClass: "span-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getFilter(
                                    this.initList.ihcKi67Type,
                                    this.caseDetail.ihcKi67
                                  )
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.caseDetail.ihcHer2
                        ? _c("div", { staticClass: "details" }, [
                            _vm._v("Her-2:"),
                            _c("span", { staticClass: "span-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getFilter(
                                    this.initList.ihcHer2Type,
                                    this.caseDetail.ihcHer2
                                  )
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.caseDetail.ihcHer2 == "20" && _vm.caseDetail.ihcFish
                        ? _c("div", { staticClass: "details" }, [
                            _vm._v("FISH检测是否扩散: "),
                            _vm.caseDetail.ihcFish
                              ? _c("span", { staticClass: "span-right" }, [
                                  _vm._v("是")
                                ])
                              : _c("span", { staticClass: "span-right" }, [
                                  _vm._v("否")
                                ])
                          ])
                        : _vm._e(),
                      _vm.caseDetail.ihcTyping
                        ? _c("div", { staticClass: "details" }, [
                            _vm._v("分型: "),
                            _c("span", { staticClass: "span-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getFilter(
                                    this.initList.ihcTyping,
                                    this.caseDetail.ihcTyping
                                  )
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "details" }, [
                        _vm._v(" 术后至今是否确诊为/怀疑为复发或远处转移："),
                        _vm.caseDetail.isMetastasis
                          ? _c("span", { staticClass: "span-right" }, [
                              _vm._v("是")
                            ])
                          : _c("span", { staticClass: "span-right" }, [
                              _vm._v("否")
                            ])
                      ])
                    ])
                  ])
                ])
              ]
            ),
            !_vm.totalImageCount
              ? _c("div", { staticClass: "jubao" }, [
                  _c("div", { staticClass: "font-red" }, [
                    _vm._v(
                      "上述信息均为您填写内容，想要获取更详细全面的投保，建议上传病历"
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "jubao-btn", on: { click: _vm.jumpUpload } },
                    [_vm._v("点击上传")]
                  )
                ])
              : _vm._e(),
            _vm.result == "04" ||
            (_vm.result == "01" && !_vm.$route.query.status)
              ? _c("div", { staticClass: "buchong" }, [
                  _c("div", { staticClass: "buchong-title" }, [
                    _vm._v("补充上传")
                  ]),
                  _c(
                    "div",
                    { staticClass: "buchong-box" },
                    [
                      _c("upload", {
                        attrs: { picList: _vm.picList, pdfList: _vm.pdfList },
                        on: {
                          getFiles: _vm.getFiles,
                          getFilespdf: _vm.getFilespdf
                        }
                      }),
                      _c("div", [
                        _vm._v("若您有新的资料可申请重新审核，添加新资料后提交")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "bc-btn",
                          on: { click: _vm.getSignature }
                        },
                        [_vm._v("提交")]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm.result == "01" && !_vm.$route.query.status
              ? _c("div", { staticStyle: { width: "100%", height: "56px" } })
              : _vm._e(),
            _vm.result == "01" && !_vm.$route.query.status
              ? _c(
                  "div",
                  { staticClass: "btn", on: { click: _vm.getInsure } },
                  [_vm._v("立即投保")]
                )
              : _vm._e()
          ]),
      _c(
        "van-popup",
        {
          staticStyle: { width: "5rem", padding: "20px" },
          attrs: { round: "", "close-on-click-overlay": false },
          model: {
            value: _vm.chongfuShow,
            callback: function($$v) {
              _vm.chongfuShow = $$v
            },
            expression: "chongfuShow"
          }
        },
        [
          _c("div", [
            _c("div", { staticStyle: { "line-height": ".4rem" } }, [
              _vm._v(
                " 本次上传存在" +
                  _vm._s(this.chongfu) +
                  "张重复资料，我们将剔除后上传，请知悉"
              )
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  "text-align": "center",
                  "margin-top": ".2rem",
                  "line-height": "32px"
                }
              },
              [
                _c(
                  "van-button",
                  {
                    staticStyle: { "line-height": "32px" },
                    attrs: { type: "info", size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.implement("5")
                      }
                    }
                  },
                  [_vm._v("已知悉 0" + _vm._s(_vm.time) + " S")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "van-popup",
        {
          staticStyle: { width: "5rem", padding: "20px" },
          attrs: { round: "", "close-on-click-overlay": false },
          model: {
            value: _vm.chongfuAll,
            callback: function($$v) {
              _vm.chongfuAll = $$v
            },
            expression: "chongfuAll"
          }
        },
        [
          _c("div", [
            _c("div", { staticStyle: { "line-height": ".4rem" } }, [
              _vm._v(" 本次上传图片此前均已提交，仅需补充新资料，请重新添加")
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  "text-align": "center",
                  "margin-top": ".2rem",
                  "line-height": "32px"
                }
              },
              [
                _c(
                  "van-button",
                  {
                    staticStyle: { "line-height": "32px" },
                    attrs: { type: "info", size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.implement("10")
                      }
                    }
                  },
                  [_vm._v("已知悉 " + _vm._s(_vm.Alltime) + " S")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "examine-top" }, [
      _c("img", { attrs: { src: require("../../assets/images/tjcg.png") } }),
      _c("p", [_vm._v("已提交成功，请您稍后回来查看预核保结果")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("img", { attrs: { src: require("../../assets/images/blzy.png") } }),
      _vm._v("疾病知识 ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "result-img" }, [
      _c("img", {
        staticStyle: { width: "28px", height: "28px" },
        attrs: { src: require("../../assets/images/jj.png") }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "result-img" }, [
      _c("img", {
        staticStyle: { width: "28px", height: "28px" },
        attrs: { src: require("../../assets/images/cg.png") }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "result-img" }, [
      _c("img", {
        staticStyle: { width: "26px", height: "28px" },
        attrs: { src: require("../../assets/images/xxbz.png") }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "result-img" }, [
      _c("img", {
        staticStyle: { width: "28px", height: "28px" },
        attrs: { src: require("../../assets/images/jj.png") }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("保障类型")]),
      _c("th", [_vm._v("限额")]),
      _c("th", [_vm._v("赔付比例")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("单位：元/年")]),
      _c("th", [_vm._v("含可选")]),
      _c("th", [_vm._v("不含可选")]),
      _c("th", [_vm._v("对应年龄段")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("img", { attrs: { src: require("../../assets/images/blzy.png") } }),
      _vm._v("病历摘要 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }