<template>
  <div>
    <div class="basics-bot">
      <van-form>
        <div class="wh-box line">
          <div style="position: relative">
            <span class="font-red">*</span> 姓名
          </div>
          <div>
            <van-field
                v-model="name"
                name="姓名"
                label=""
                placeholder="请输入姓名"
                input-align="right"
                disabled
            />
          </div>
        </div>
        <div class="wh-box line">
          <div style="position: relative">
            <span class="font-red">*</span> 出生日期
          </div>
          <div>
            <van-field
                readonly
                clickable
                name="picker"
                :value="birthday"
                label=""
                input-align="right"
                placeholder="请选择出生日期"
                @click="showBirthday = true"
            />
          </div>

        </div>

        <!-- :rules="[{ required: true, message: '请选择出生日期' }]" -->
        <van-popup v-model="showBirthday" position="bottom">
          <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="getBirthday"
              @cancel="showBirthday=false"
          />
        </van-popup>
        <div class="wh-box line">
          <div style="position: relative">
            <span class="font-red">*</span> 有无社保
          </div>
          <div>
            <pickerPopup :columns='shebaoValue'  title = '' :val='ruleForm.isSocialSecurity'  @childByValue="getIsSocialSecurity"></pickerPopup>
          </div>
        </div>
        <div class="wh-box line" style="position: relative">
          <div style="width: 65%">
            <span class="font-red">*</span> 是否新辅助治疗
          </div>
          <div>
            <pickerPopup :columns='xuanzeValue' title = '' :val='ruleForm.isCompTherapy'  @childByValue="getIsCompTherapy"></pickerPopup>
          </div>
        </div>
        <div class="line">
          <div class="wh-box">
            <div style="position: relative">
              <span class="font-red">*</span> 保额
            </div>
            <div>
              <pickerPopup :columns='initList.insuredSum' title = '' :val='ruleForm.insuredSum' @childByValue="getInsuredSum"></pickerPopup>
            </div>
          </div>
          <div class="bule-zhu " v-if="ruleForm.insuredSum == '150'">保额含：社保内限额50万、社保外限额50万、特定恶性肿瘤药品限额50万</div>
          <div class="bule-zhu " v-if="ruleForm.insuredSum == '10'">保额含：社保内限额10万</div>
          <div class="bule-zhu " v-if="ruleForm.insuredSum == '40'">保额含：社保内限额20万、社保外限额10万、特定恶性肿瘤药品限额10万</div>
        </div>
        <div class="wh-box line">
          <div style="width: 70%; padding-top: .2rem">
            <span class="font-red">*</span> 社保范围内的自付费用赔付比例
          </div>
          <div>
            <pickerPopup :columns='initList.compensateRatioIn' title = '' :zifu="zifu" :val='ruleForm.inSocialPercentage' @childByValue="getInSocialPercentage"></pickerPopup>
          </div>
        </div>
        <div class="fw line">社保范围外的自费费用赔付比例<span  v-if="ruleForm.outSocialPercentage!=''">{{ruleForm.outSocialPercentage}}%</span></div>
        <div class="wh-box">
          <div style="width: 70%">
            <span class="font-red">*</span> 重大疾病保险金1万(可选)
          </div>
          <div>
            <pickerPopup :columns='xuanzeValue' title = '' :val='ruleForm.isSeriousIllAmount1W'  @childByValue="getIsSeriousIllAmount1W"></pickerPopup>
          </div>
        </div>
      </van-form>
    </div>
  </div>




</template>

<script>

import {checkBirthday, enumList,nameBirthday} from '@/api'
import Vue from 'vue'
import pickerPopup from '../module/pickerPopup.vue'
import {Form, Field, Button, DatetimePicker, Popup, RadioGroup, Radio, Toast, Dialog} from 'vant';
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Radio);
Vue.use(RadioGroup);
export default {
  name: 'medical-recored',
  data() {
    return {
      name:"",
      birthday:"",
      value:"",
      showBirthday:false,
      showPicker:false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      ruleForm:{},
      shebaoValue:[{
        name:'有',
        code:true
      },{
        name:'无',
        code:false
      }],
      xuanzeValue:[{
        name:'是',
        code:true
      },{
        name:'否',
        code:false
      }],
      zifu:""
    }
  },
   components: {
     pickerPopup,
   },
   watch:{
     ruleForm:{
       handler(newValue, oldValue) {
          this.$emit('getBasics',newValue)
       },
       deep: true 
     },
     name(val){
        this.$emit('getNewName',val)
     },
     birthday(val){
        this.$emit('getNewBirthday',val)
     }
   },
   props: {
    caseDetail: {
      type: Object,
      default: [],
    },
    getname:{
      type: String,
      default: '',
    },
     getbirthday:{
      type: String,
      default: '',
    },
    initList:{
      type: Object,
      default: '',
    },
  },
  created() {
    this.ruleForm  = this.caseDetail
    this.name = this.getname
    this.birthday = this.getbirthday
  },
  methods: {
     checked(j){
       if(j<10)
        j='0'+j;
      return j},
    //获取生日
    getBirthday(){
      let date = new Date(this.currentDate)
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      let query={
        birthday:y+'-'+this.checked(m)+'-'+this.checked(d)
      }
      checkBirthday(query).then((res)=>{
        this.showBirthday= false
        this.birthday = y+'-'+this.checked(m)+'-'+this.checked(d)
        this.getNameBirthday()
      })
    },
    getNameBirthday(){
      let query={
        birthday:this.birthday,
        name:this.name
      }
      nameBirthday(query).then((res)=>{
        console.log(res)
        if(res && this.caseId != res.id){
          Dialog.alert({
            title: '温馨提示',
            message: '姓名和出生日期与此前已录入案件重复，请重新修改出生日期',
          }).then(() => {
            // on close
            this.birthday = this.getbirthday
          });
        }
      })
    },
    //保额
    getInsuredSum(val){
      this.ruleForm.insuredSum = val
      this.zifu = Date.parse(new Date())
      this.ruleForm.inSocialPercentage = '';
      this.ruleForm.outSocialPercentage = ''
    },
    getInSocialPercentage(val,other){
      this.ruleForm.inSocialPercentage = val;
      this.ruleForm.outSocialPercentage = other.code
    },
    getIsCompTherapy(val){
      if( this.ruleForm.pathologyTyping =='01' && val){
        this.fenqi = true
        Toast('新辅助治疗不可选择0期，请核实真实情况，上传病历由人工审核！');
      }else {
        this.ruleForm.isCompTherapy = val
      }
    },
    getIsSocialSecurity(val){
      this.ruleForm.isSocialSecurity = val
    },
    getIsSeriousIllAmount1W(val){
      this.ruleForm.isSeriousIllAmount1W = val
    },
    
  },
}
</script>

<style lang="scss" scoped>
.basics-bot{
  background: #FFFFFF;
  box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
  border-radius: 8px;
  padding: .3rem;
}
.bule-zhu{
  font-size: .24rem;
  color: rgba(0, 104, 255, .5);
  padding: 0 .16rem;
}
.wh-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #646566;
  font-size: .26rem;
  img{
    width: .24rem;
    height: .24rem;
  }
}
.font-red{
  color: #FF0000;
}
.fw{
  color: #646566;
  padding: 10px 16px 10px 0;
  font-size: .26rem;
  line-height: 24px;
  span{
    float:right;
    color: #323233;
  }
}
</style>
<style>
#container .van-field__label{
  width: 40%;
}
</style>